<template>
	<w-section fill-height :title="$t('customers.wizard.administrator', { step: step })">
        <v-layout column fill-height>
			<v-flex grow>
				<v-form ref="form" v-model="isFormValid">
					<v-layout row wrap>
						<v-flex xs12>
							<w-email-input v-model="customer.email" :label="$t('email')" :loading="userLoading" required />
						</v-flex>
						<v-flex pr-1 xs6>
							<w-text-input v-model="customer.lastname" :disabled="!canEditName" :label="$t('last_name')" required />
						</v-flex>
						<v-flex pl-1 xs6>
							<w-text-input v-model="customer.firstname" :disabled="!canEditName" :label="$t('first_name')" required />
						</v-flex>
						<v-flex v-if="hasEcmModule" xs12>
							<SelectWithChips
								v-model="selectedCatalogThemes"
								hide-details
								:item-clearable="false"
								item-text="title"
								item-value="id"
								:items="catalogThemes"
								:label="$t('customers.labels.themes')"
								:loading="catalogThemesLoading"
								:max-selected-items-showed="10"
								multiple
								return-object
								single-line
							/>
						</v-flex>
						<v-flex xs12>
							<w-checkbox v-model="customer.send_mail" :label="$t('customers.labels.send_invitation')" hide-details />
						</v-flex>
					</v-layout>
				</v-form>
			</v-flex>
			<v-flex shrink text-xs-right>
				<w-btn :disabled="!isValid" @click="next">{{ $t('next') }}</w-btn>
			</v-flex>
		</v-layout>
	</w-section>
</template>

<script>
import { Bus, Events } from '@/events/AppEvents'
import Validator from '@/mixins/Validator'

export default {
	name: 'CustomerCreationUser',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	mixins: [Validator],
    inject: {
        holdingContext: {
            default: () => ({}),
            from: 'holdingContext'
        },
        service: {
            from: 'service'
        }
    },
	props: {
		hasEcmModule: {
			default: true,
			required: false,
			type: Boolean
		},
		step: {
			required: true,
			type: Number
		},
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			canEditName: false,
			catalogThemes: [],
			catalogThemesLoading: false,
			isFormValid: false,
			selectedCatalogThemes: [],
			userAlreadyExist: false,
			userLoading: false,
			userTimeout: null
		}
	},
	computed: {
		customer: {
			get: function () {
				return this.value
			},
			set: function (customer) {
				this.$emit('input', customer)
			}
		},
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		isValid: function () {
			return this.isFormValid || (this.userAlreadyExist && (!this.customer.firstname || !this.customer.lastname))
		}
	},
	watch: {
		'customer.catalog_tree_structure_id': {
			handler: function (catalogTreeStructureId) {
				if (catalogTreeStructureId) {
					this.loadCatalogThemes()
				}
			}
		},
		'customer.email': {
			handler: function (val) {
				if (this.isEmailValid(val)) {
					if (this.userTimeout) {
						clearTimeout(this.userTimeout)
						this.userTimeout = null
					}
					this.userTimeout = setTimeout(function () {
						this.getUserInfoByEmail(val)
					}.bind(this), 1000)
				} else {
					if (this.userTimeout) {
						clearTimeout(this.userTimeout)
						this.userTimeout = null
					}
					if (this.userAlreadyExist) {
						this.customer.firstname = ''
						this.customer.lastname = ''
					}
					this.canEditName = false
					this.userAlreadyExist = false
				}
			}
		}
	},
	mounted: function () {
		if (this.customer?.catalog_tree_structure_id) {
			this.loadCatalogThemes()
		}
		this.customer.send_mail = true
	},
	methods: {
		getUserInfoByEmail: function (email) {
			this.userLoading = true
			this.service
				.findUserByEmail(email, { show_error: false })
				.then(user => {
					let promise
					if (this.holdingId) {
						promise = this.service.findAccountant(this.holdingId, this.customer.accounting_firm_id, user.id)
					} else {
						promise = this.service.findAccountant(this.customer.accounting_firm_id, user.id)
					}
					return promise.then(accountant => {
							if (accountant) {
								Bus.emit(Events.SNACKBAR_ERROR, this.$t('clients.client_user_is_accountant'))
								return null
							} else {
								return user
							}
						})
				})
				.then(user => {
					if (user) {
						this.userAlreadyExist = true
						this.canEditName = false
						this.customer.firstname = user.firstname
						this.customer.lastname = user.lastname
						this.$refs.form.validate()
					} else {
						if (this.userAlreadyExist) {
							this.customer.firstname = ''
							this.customer.lastname = ''
						}
						this.userAlreadyExist = false
						this.canEditName = false
					}
				})
				.catch(() => {
					if (this.userAlreadyExist) {
						this.customer.firstname = ''
						this.customer.lastname = ''
					}
					this.userAlreadyExist = false
					this.canEditName = true
					this.$refs.form.validate()
				})
				.finally(() => {
					this.userLoading = false
				})
		},
		isEmailValid: function (email) {
			return this.validateEmail(email)
		},
		loadCatalogThemes: function () {
			this.catalogThemesLoading = true

			return this.service.listCatalogThemes(
				this.customer.catalog_tree_structure_id,
				{
					params : {
						is_for_companies: true
					}
				})
				.then(catalogThemes => {
					this.catalogThemes = catalogThemes
					this.selectedCatalogThemes = this.catalogThemes.map(ct => { return {...ct}})
				})
				.finally(() => {
					this.catalogThemesLoading = false
				})
		},
		next: function () {
			this.customer.user_themes = [...this.selectedCatalogThemes]
			this.$emit('next')
		},
		reset: function () {
			this.$refs.form.reset()
			this.customer.send_mail = true

			if (this.customer.catalog_tree_structure_id) {
				this.selectedCatalogThemes = this.catalogThemes.map(ct => { return {...ct}})
			}
		}
	}
}
</script>
